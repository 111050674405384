import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "./index.scss";
import store from "./store";
import { Provider } from "react-redux";
import routes, { RenderRoutes } from "./routes";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorker from "./serviceWorker";
import { AuthProvider } from "./context/AuthProvider";
import ModalsProvider from "./components/ModalsProvider";
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <AuthProvider>
          <RenderRoutes routes={routes}/>
          <ModalsProvider />
        </AuthProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
serviceWorker.unregister();
